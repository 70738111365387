import React, { PropsWithChildren, FC, useMemo } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { NoData, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  useSetIncomingTransferIdToUrl,
  useIncomingTransferIdFromUrl,
  useIncomingTransferByUrl,
} from '../../hooks/transfers/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { TransferIconWithStatus } from '../../components/TransferIconWithStatus/TransferIconWithStatus';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@payler/ui-icons';
import { InfoItem } from '../helpers/InfoItem';
import { TransferStatementButton } from '../../components/TransferStatementButton/TransferStatementButton';
import { SelfTransferAccountInfo } from '../helpers/SelfTransferAccountInfo';
import { isNil } from 'lodash';
import { useParticipantBankInfo } from '../../hooks/use-participant-bank-info';

export const IncomingTransferInfoDrawer = () => {
  const incomingTransferId = useIncomingTransferIdFromUrl();
  const isOpen = !!incomingTransferId;
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();

  return (
    <BankDrawer isOpen={isOpen} onClose={setIncomingTransferId}>
      <BankDrawerContent drawerId="incoming-transfer-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <ThinDivider />
          <Body />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const incomingTransferId = useIncomingTransferIdFromUrl();
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <HStack spacing={2}>
          {incomingTransferId && (
            <TransferStatementButton transferId={incomingTransferId} />
          )}
          <IconButton
            icon={<CrossIcon />}
            aria-label="close"
            variant="secondary"
            w={6}
            h={6}
            onClick={() => {
              setIncomingTransferId();
            }}
            data-testid="button_drawer-close"
          />
        </HStack>
      </HStack>
    </DrawerHeader>
  );
};

const Header = () => {
  const { t } = useTranslation('transfers');
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const transfer = useIncomingTransferByUrl();

  const isSelfTransfer = transfer?.operationType === 'self';

  if (!transfer) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        <TransferIconWithStatus
          status={transfer.state}
          type="incoming"
          isSelfTransfer={isSelfTransfer}
        />
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text
          textStyle={TextStyles.h4}
          color="primary.500"
          maxW="200px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {isSelfTransfer ? t('transferToSelf') : transfer.sender.legalName}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {serverDatetimeToDisplay(transfer?.created, 'datetime')}
        </Text>
      </VStack>
    </HStack>
  );
};

const Body = () => {
  const { t } = useTranslation('transfers');
  const transfer = useIncomingTransferByUrl();
  const { formatAmountByCurrency, serverDatetimeToDisplay } =
    useLanguageFeatures();

  const isSelfTransfer = transfer?.operationType === 'self';

  const { label: bankInfoLabel, value: bankInfoValue } = useParticipantBankInfo(
    {
      name: transfer?.sender.account.bankName,
      country: transfer?.sender.account.bankCountry,
      bic: transfer?.sender.account.bic,
    },
  );

  if (!transfer) {
    return <NoData />;
  }

  return (
    <DrawerBody p={0}>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        <VStack spacing={2} alignItems="stretch" p={3}>
          {isSelfTransfer ? (
            <SelfTransferAccountInfo
              senderAccountNumber={transfer.sender.account.accountNumber}
              receiverAccountNumber={transfer.recipient.account.accountNumber}
            />
          ) : (
            <>
              <Text as="h4" textStyle={TextStyles.h4}>
                {t('senderInfo')}
              </Text>
              <InfoItem label={t('sender')} value={transfer.sender.legalName} />
              <InfoItem
                label={t('senderCountry')}
                value={transfer.sender.registrationAddress.country}
              />
              <InfoItem
                label={t('iban')}
                value={transfer.sender.account.iban}
              />
              <InfoItem
                label={t('accountNumber')}
                value={transfer.sender.account.accountNumber}
              />
              <InfoItem
                label={t('ukAccountNumber')}
                value={transfer.sender.account.ukAccountNumber}
              />
              <InfoItem
                label={t('ukSortCode')}
                value={transfer.sender.account.ukSortCode}
              />
              <InfoItem label={bankInfoLabel} value={bankInfoValue} />
            </>
          )}
        </VStack>
        <VStack spacing={2} alignItems="stretch" p={3}>
          <Text as="h4" textStyle={TextStyles.h4}>
            {t('transferInfo')}
          </Text>
          <InfoItem
            label={t('date')}
            value={serverDatetimeToDisplay(transfer?.created, 'datetime')}
          />
          <InfoItem label={t('transferId')} value={transfer.id} />
          <InfoItem
            label={t('amount')}
            value={formatAmountByCurrency(
              transfer.recipientSide.amount || 0,
              transfer.recipientSide.currency,
            )}
          />
          {!isSelfTransfer && (
            <>
              {!isNil(transfer.recipientSide.feeAmount) && (
                <InfoItem
                  label={t('fee')}
                  value={formatAmountByCurrency(
                    transfer.recipientSide.feeAmount,
                    transfer.recipientSide.currency,
                  )}
                />
              )}
              {!isNil(transfer.recipientSide.totalAmount) && (
                <InfoItem
                  label={t('totalAmount')}
                  value={formatAmountByCurrency(
                    transfer.recipientSide.totalAmount,
                    transfer.recipientSide.currency,
                  )}
                />
              )}
              <InfoItem
                label={t('system')}
                value={t(`mappedSystems.${transfer.system}`)}
              />
              <InfoItem label={t('description')} value={transfer.description} />
            </>
          )}
          <InfoItem
            label={t('status')}
            value={t(`statuses.${transfer.state}`)}
          />
        </VStack>
      </VStack>
    </DrawerBody>
  );
};
