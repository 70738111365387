import { useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';
import { useTransferWizardContext } from './TransferWizard';
import {
  EMAIL_REGEXP,
  REQUISITES_NOT_FOUND_ERROR,
  useGetAxiosError,
} from '@payler/bank-utils';
import { useMemo } from 'react';
import { Link, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const TransferError = ({ close }: { close: VoidFunction }) => {
  const { t } = useTranslation(['accounts']);
  const { error } = useTransferWizardContext();
  const getError = useGetAxiosError();

  const description = useMemo(() => {
    const { fieldErrors } = getError(error);
    const fieldError = fieldErrors?.[0];
    if (fieldError?.fieldErrorCode === REQUISITES_NOT_FOUND_ERROR) {
      const descriptionSplitted = fieldError.errorMessage.split(EMAIL_REGEXP);
      return (
        <Text
          textStyle={TextStyles.BodyText16Regular}
          textAlign="center"
          color="primary.400"
        >
          {descriptionSplitted.map((part) =>
            EMAIL_REGEXP.test(part) ? (
              <Link
                isExternal
                href={`mailto:${part}`}
                textStyle={TextStyles.BodyText16Medium}
                colorScheme="primary"
              >
                {part}
              </Link>
            ) : (
              part
            ),
          )}
        </Text>
      );
    }
    return null;
  }, [getError, error]);

  return (
    <EventStatus
      statusType="warning"
      title={t('accounts:transferMoney.error.title')}
      description={description}
      onCloseButtonClick={() => {
        close();
      }}
      closeButtonProps={{ variant: 'secondary' }}
    />
  );
};
