import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { mergeDeep } from './merge-deep';
import { useCustomTheme } from './use-custom-theme';
import { isEmpty } from 'lodash';
import { Loader } from '@payler/ui-components';

type TTheme = Record<string, any>;

type TWhiteLabelThemeContext = {
  setTheme: (extendableTheme: TTheme) => void;
  theme?: TTheme | null;
};

const WhiteLabelThemeContext = createContext<TWhiteLabelThemeContext>({
  setTheme: (extendableTheme: TTheme) => {},
});

export const WhiteLabelThemeProvider: FCC<{ theme: TTheme; path?: string }> = ({
  theme,
  children,
  path,
}) => {
  const { data } = useCustomTheme(path ?? '');
  const [customTheme, setCustomTheme] = useState<TTheme | null>(null);
  const handleSetCustomTheme = useCallback(
    (extendableTheme: TTheme) => {
      if (!isEmpty(extendableTheme)) {
        setCustomTheme((prevState) => {
          return extendTheme(mergeDeep(prevState || theme, extendableTheme));
        });
      }
    },
    [setCustomTheme],
  );

  useEffect(() => {
    if (isEmpty(data)) return;
    if (!isEmpty(data)) handleSetCustomTheme(data);
  }, [data, handleSetCustomTheme]);

  const ctx = useMemo(
    () => ({
      setTheme: handleSetCustomTheme,
      theme: customTheme,
    }),
    [customTheme, handleSetCustomTheme],
  );

  if (!customTheme) return <Loader />;

  return (
    <WhiteLabelThemeContext.Provider value={ctx}>
      <ChakraProvider theme={customTheme}>{children}</ChakraProvider>
    </WhiteLabelThemeContext.Provider>
  );
};

export const useWhiteLabelContext = () => useContext(WhiteLabelThemeContext);
