import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { Button, VStack, Text, Box, HStack } from '@chakra-ui/react';
import {
  ApiErrorText,
  DropdownField,
  FloatingInputField,
  SegmentedPicker,
  TDropdownOption,
} from '@payler/ui-components';
import {
  COUNTRY_OPTIONS,
  UK_COUNTRY_CODE,
  UK_CURRENCY_CODE,
} from '../../const';
import { useAddRecipientWizardContext } from './AddRecipientWizard';
import { TextStyles } from '@payler/ui-theme';
import {
  TCreateRecipientFields,
  TRecipientType,
} from '@payler/api/client-office';
import { useAddRecipientMutation } from '../../hooks/recipients/mutations';
import { fieldsMap, getStructuredRecipientParam } from './helpers';
import { useApi } from '../../state/api';
import {
  namedCurrencyOptions,
  useGetAxiosError,
  useHandleFormError,
  useToasts,
} from '@payler/bank-utils';
import { useCompanyInfo } from '../../config/ConfigProvider';

type TRecipientMainType = Exclude<TRecipientType, 'self'>;

const selectionIndex: Record<TRecipientMainType, number> = {
  corporate: 0,
  individual: 1,
  internal: 2,
};

export const RecipientInfoForm = () => {
  const {
    setStep,
    onFirstStepBack,
    setRecipient,
    onFinishWizard,
    senderAccount,
  } = useAddRecipientWizardContext();
  const { t } = useTranslation(['recipients', 'common']);
  const api = useApi();
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const handleFormError = useHandleFormError<TCreateRecipientFields>();
  const [errorText, setErrorText] = useState<string>('');

  const methods = useFormContext<TCreateRecipientFields>();
  const {
    watch,
    handleSubmit: rhfHandleSubmit,
    getValues,
    unregister,
  } = methods;
  const { mutate: addRecipient, isPending } = useAddRecipientMutation();
  const recipientType = watch('recipientType');
  const paymentType = watch('paymentType');

  const isInternalRecipient = useMemo(
    () => recipientType === 'internal',
    [recipientType],
  );
  const { companyName } = useCompanyInfo();

  const recipientFieldValues = getValues();

  const createRecipientParam = getStructuredRecipientParam({
    recipientFieldValues,
    isVisible: !setRecipient,
    internalAccountCurrency: senderAccount?.currency,
  });

  const handleSubmit = rhfHandleSubmit(() => {
    setErrorText('');
    addRecipient(createRecipientParam, {
      onSuccess: async (recipientId: string) => {
        if (setRecipient) {
          try {
            const createdRecipient = await api.getRecipientById(recipientId);
            setRecipient(createdRecipient);
          } catch (e) {
            toasts.error(getError(e).errorTitle);
          } finally {
            onFinishWizard?.();
          }
        } else {
          setStep('success');
        }
      },
      onError: (e: unknown) => {
        const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
        if (unknownFieldErrors.length)
          setErrorText(unknownFieldErrors.join(' '));
        throw e;
      },
    });
  });

  useEffect(() => {
    if (paymentType === 'fps') {
      unregister('accountIdentifier');
      unregister('bic');
      unregister('city');
      unregister('postalCode');
      unregister('street');
      return;
    }
    if (paymentType === 'swift') {
      unregister('ukAccountNumber');
      unregister('ukSortCode');
    }
  }, [paymentType]);

  return (
    <>
      <ModalHeaderActions
        isShowBack={!!onFirstStepBack}
        onBack={onFirstStepBack}
      />
      <BankModalTitle
        title={t('recipients:addModal.recipientInfo')}
        description={t('recipients:addModal.description')}
      />
      <BankModalBody>
        <ChooseType />
        <VStack w="full" spacing={2}>
          {isInternalRecipient && (
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('recipients:addModal.accountInfoMessage', { companyName })}
            </Text>
          )}
          <Names />
        </VStack>
        {isInternalRecipient ? (
          <InternalRecipientFields errorText={errorText} />
        ) : (
          <CommonFields errorText={errorText} />
        )}
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={handleSubmit}
          data-testid="button_continue"
          isLoading={isPending}
        >
          {!!setRecipient ? t('common:continue') : t('common:confirm')}
        </Button>
      </BankModalFooter>
    </>
  );
};

const InternalRecipientFields = ({ errorText }: { errorText: string }) => {
  const { t } = useTranslation('recipients');
  return (
    <VStack w="full" spacing={2} mt={2} alignItems="flex-start">
      <FloatingInputField
        name="internalAccountIdentifier"
        label={t('infoDrawer.accountNumberOrIban')}
      />
      {/* TODO: BANK-4779 Запрос и отображение валюты внутреннего аккаунта номеру счета выключены
                Вернуть и доработать функционал после завершения аналитики по задаче BANK-4778
            */}
      {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
    </VStack>
  );
};

const CommonFields = ({ errorText }: { errorText: string }) => {
  const { t } = useTranslation('recipients');
  const { senderAccount } = useAddRecipientWizardContext();
  const methods = useFormContext<TCreateRecipientFields>();
  const {
    formState: { errors },
    watch,
  } = methods;
  const country = watch('country');
  const currency = watch('currency');
  const paymentType = watch('paymentType');

  const isUKPayment = useMemo(
    () =>
      country?.toUpperCase() === UK_COUNTRY_CODE &&
      currency?.toUpperCase() === UK_CURRENCY_CODE,
    [country, currency],
  );

  const PAYMENT_TYPE_OPTIONS: TDropdownOption[] = useMemo(
    () => [
      { label: t('paymentType.swift'), value: 'swift' },
      {
        label: t('paymentType.fps'),
        value: 'fps',
      },
    ],
    [t],
  );

  return (
    <VStack w="full" alignItems="stretch" spacing={2} mt={2}>
      <DropdownField
        options={COUNTRY_OPTIONS}
        fieldName="country"
        label={t('addModal.selectCountry')}
        floating
        isSearchable
        isError={!!errors.country}
        /**
         * По документации парамтеров input'а autocomplete должен быть off,
         * но для браузера Edge данный способ не работает.
         * Подходит любое значение
         */
        autocomplete="none"
        testId="field_country"
      />
      <DropdownField
        options={namedCurrencyOptions}
        fieldName="currency"
        label={t('addModal.accountCurrency')}
        floating
        isError={!!errors.currency}
        testId="field_currency"
        isDisabled={!!senderAccount}
      />
      {isUKPayment && (
        <DropdownField
          fieldName="paymentType"
          options={PAYMENT_TYPE_OPTIONS}
          label={t('addModal.type')}
          floating
          hideClearButton
        />
      )}
      {isUKPayment && paymentType === 'fps' ? (
        <>
          <FloatingInputField
            name="ukAccountNumber"
            label={t('addModal.ukAccountNumber')}
            key="ukAccountNumber"
          />
          <FloatingInputField
            name="ukSortCode"
            label={t('addModal.ukSortCode')}
            key="ukSortCode"
          />
        </>
      ) : (
        <>
          <FloatingInputField
            name="accountIdentifier"
            label={t('addModal.accountIdentifier')}
            key="accountIdentifier"
          />
          <FloatingInputField name="bic" label={t('addModal.bic')} key="bic" />
          <AddressFields />
        </>
      )}
      <DropdownField
        options={COUNTRY_OPTIONS}
        fieldName="bankCountry"
        label={t('addModal.bankCountry')}
        floating
        isSearchable
        isError={!!errors.bankCountry}
        autocomplete="none"
        testId="field_recipient-bank-country"
      />
      <FloatingInputField name="bankName" label={t('addModal.bankName')} />
      {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
    </VStack>
  );
};

const Names = () => {
  const { t } = useTranslation(['recipients']);
  const { watch } = useFormContext<TCreateRecipientFields>();
  const type = watch('recipientType');
  const isCorporate = type === 'corporate';
  return (
    <>
      {isCorporate && (
        <FloatingInputField
          name="legalName"
          label={t('recipients:addModal.legalName')}
        />
      )}
      {!isCorporate && (
        <HStack spacing={2} alignItems="flex-start">
          <FloatingInputField
            name="firstName"
            label={t('recipients:addModal.firstName')}
          />
          <FloatingInputField
            name="lastName"
            label={t('recipients:addModal.lastName')}
          />
        </HStack>
      )}
    </>
  );
};

const ChooseType = () => {
  const { t } = useTranslation(['recipients']);
  const { watch, setValue, unregister } =
    useFormContext<TCreateRecipientFields>();
  const type = watch('recipientType');
  const { senderAccount } = useAddRecipientWizardContext();

  const selection = selectionIndex[type as TRecipientMainType];

  const typeOptions = useMemo(() => {
    return senderAccount
      ? [
          t('recipients:corporate'),
          t('recipients:individual'),
          t('recipients:internal'),
        ]
      : [t('recipients:corporate'), t('recipients:individual')];
  }, [t, senderAccount]);

  const handleChange = useCallback(
    (recipientTypeIndex: number) => {
      switch (recipientTypeIndex) {
        case 0:
          setValue('recipientType', 'corporate');
          unregister('firstName');
          unregister('lastName');
          break;
        case 1:
          setValue('recipientType', 'individual');
          unregister('legalName');
          unregister('firstName');
          unregister('lastName');
          break;
        case 2:
          setValue('recipientType', 'internal');
          unregister('legalName');
          unregister('firstName');
          unregister('lastName');
      }
    },
    [setValue, unregister],
  );

  return (
    <Box mb={3}>
      <SegmentedPicker
        options={typeOptions}
        onSelectionChange={handleChange}
        selection={selection}
        isFullWidth
      />
    </Box>
  );
};

const AddressFields = () => {
  const { t } = useTranslation('recipients');
  return (
    <>
      <HStack spacing={2}>
        <FloatingInputField
          name="postalCode"
          label={t('addModal.zipPostalCode')}
        />
        <FloatingInputField name="city" label={t('addModal.cityTown')} />
      </HStack>
      <FloatingInputField name="street" label={t('addModal.street')} />
    </>
  );
};
