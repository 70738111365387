import React from 'react';
import {
  HStack,
  Radio,
  RadioGroup,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import {
  FormOption,
  FormOptionProps,
} from '../components/FormOption/FormOption';

export type TRadioFieldProps = {
  options: { label?: string; value: string; checked?: boolean }[];
  name: string;
  label?: FormOptionProps['label'];
  direction?: 'horizontal' | 'vertical';
  isDisabled?: boolean;
};

export const RadioField: React.FC<TRadioFieldProps> = ({
  direction = 'horizontal',
  label,
  name,
  isDisabled,
  options,
}) => {
  const { control } = useFormContext();
  const {
    field,
    formState: { errors, touchedFields },
  } = useController({
    control,
    name,
  });
  const { getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: field.value,
  });
  const errorMessage = errors[name]?.message;
  const isError = !!errorMessage && !!touchedFields;
  const Container = direction === 'horizontal' ? HStack : VStack;
  return (
    <FormOption
      isInvalid={isError}
      label={label}
      errorMessage={errorMessage as string | undefined}
    >
      <RadioGroup
        name={name}
        value={field.value}
        isDisabled={isDisabled}
        variant="activeLabel"
      >
        <Container spacing={4}>
          {options.map((item, index) => {
            const radio = getRadioProps({
              value: item.value,
              checked: !!item.checked,
            });
            return (
              <Radio key={index} {...radio}>
                {item.label}
              </Radio>
            );
          })}
        </Container>
      </RadioGroup>
    </FormOption>
  );
};
RadioField.displayName = 'RadioField';
