import { FC } from 'react';
import { LinkProps, Link } from '@chakra-ui/react';
import {
  Link as RouterDomLink,
  LinkProps as RouterDomLinkProps,
} from 'react-router-dom';

export const MobileFriendlyLink: FC<
  LinkProps & RouterDomLinkProps & { loadIn?: 'inApp' | 'defaultBrowser' }
> = ({ loadIn = 'inApp', ...rest }) => {
  return loadIn === 'defaultBrowser' ? (
    <Link {...rest} as={RouterDomLink} data-load-in="defaultBrowser" />
  ) : (
    <Link {...rest} as={RouterDomLink} />
  );
};

export default MobileFriendlyLink;
