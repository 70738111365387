import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

import { Button, VStack, Text, Box, HStack } from '@chakra-ui/react';
import {
  ApiErrorText,
  FloatingInputField,
  SegmentedPicker,
} from '@payler/ui-components';

import { useAddRecipientWizardContext } from './TransfersAddRecipientWizard';
import { TextStyles } from '@payler/ui-theme';
import {
  TCreateRecipientFields,
  TRecipientType,
} from '@payler/api/client-office';

import { fieldsMap, getStructuredRecipientParam } from './helpers';

import {
  useGetAxiosError,
  useHandleFormError,
  useToasts,
} from '@payler/bank-utils';
import { useSpushApi } from '../../hooks/useSpushApi';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
  useCompanyInfo,
} from '@payler/ui/client-office';
import { useAddRecipientMutation } from '../../hooks/recipients/mutations';

type TRecipientMainType = Exclude<TRecipientType, 'self'>;

const selectionIndex: Record<TRecipientMainType, number> = {
  corporate: 0,
  individual: 1,
  internal: 2,
};

export const TransferRecipientInfoForm = () => {
  const {
    setStep,
    onFirstStepBack,
    setRecipient,
    onFinishWizard,
    senderAccount,
  } = useAddRecipientWizardContext();
  const { t } = useTranslation(['recipients', 'common']);
  const api = useSpushApi();
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const handleFormError = useHandleFormError<TCreateRecipientFields>();
  const [errorText, setErrorText] = useState<string>('');

  const methods = useFormContext<TCreateRecipientFields>();
  const {
    formState: { errors },
    trigger,
    watch,
    handleSubmit: rhfHandleSubmit,
    getValues,
  } = methods;
  const { mutate: addRecipient, isPending } = useAddRecipientMutation();
  const recipientType = watch('recipientType');
  const isInternalRecipient = useMemo(
    () => recipientType === 'internal',
    [recipientType],
  );
  const { companyName } = useCompanyInfo();

  const recipientFieldValues = getValues();

  const createRecipientParam = getStructuredRecipientParam({
    recipientFieldValues,
    isVisible: !setRecipient,
    internalAccountCurrency: senderAccount?.currency,
  });

  const handleSubmitInternal = rhfHandleSubmit(() => {
    setErrorText('');
    addRecipient(createRecipientParam, {
      onSuccess: async (recipientId: string) => {
        if (setRecipient) {
          try {
            const createdRecipient = await api.getRecipientById(recipientId);
            setRecipient(createdRecipient);
          } catch (e) {
            toasts.error(getError(e).errorTitle);
          } finally {
            onFinishWizard?.();
          }
        } else {
          setStep('success');
        }
      },
      onError: (e: unknown) => {
        const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
        if (unknownFieldErrors.length)
          setErrorText(unknownFieldErrors.join(' '));
        throw e;
      },
    });
  });

  const handleSubmit = rhfHandleSubmit(() => {
    addRecipient(createRecipientParam, {
      onSuccess: async (recipientId: string) => {
        if (setRecipient) {
          try {
            const createdRecipient = await api.getRecipientById(recipientId);
            setRecipient(createdRecipient);
          } catch (e) {
            toasts.error(getError(e).errorTitle);
          } finally {
            onFinishWizard?.();
          }
        } else {
          setStep('success');
        }
      },
      onError: (e: unknown) => {
        const unknownFieldErrors = handleFormError(e, methods, fieldsMap);
        if (unknownFieldErrors.length)
          setErrorText(unknownFieldErrors.join(' '));
        throw e;
      },
    });
  });

  const onConfirmClick = useCallback(
    () =>
      trigger(['legalName', 'firstName', 'lastName', 'accountIdentifier']).then(
        (isValid) => {
          if (isValid) handleSubmit();
        },
      ),
    [handleSubmit, trigger],
  );

  return (
    <>
      <ModalHeaderActions
        isShowBack={!!onFirstStepBack}
        onBack={onFirstStepBack}
      />
      <BankModalTitle
        title={t('recipients:addModal.recipientInfo')}
        description={t('recipients:addModal.description')}
      />
      <BankModalBody>
        <ChooseType />
        <VStack w="full" spacing={2}>
          {isInternalRecipient && (
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {t('recipients:addModal.accountInfoMessage', { companyName })}
            </Text>
          )}
          <Names />
        </VStack>
        {isInternalRecipient ? (
          <VStack w="full" spacing={2} mt={2} alignItems="flex-start">
            <FloatingInputField
              name="internalAccountIdentifier"
              label={t('recipients:infoDrawer.accountNumber')}
            />
            {/* TODO: BANK-4779 Запрос и отображение валюты внутреннего аккаунта номеру счета выключены
                Вернуть и доработать функционал после завершения аналитики по задаче BANK-4778
            */}
            {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
          </VStack>
        ) : (
          <VStack w="full" alignItems="start" spacing={2} mt={3}>
            <FloatingInputField
              name="accountIdentifier"
              label={t('recipients:infoDrawer.iban')}
            />
          </VStack>
        )}
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={isInternalRecipient ? handleSubmitInternal : onConfirmClick}
          data-testid="button_continue"
          isLoading={isPending}
        >
          {isInternalRecipient && !setRecipient
            ? t('common:confirm')
            : t('common:continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};

const Names = () => {
  const { t } = useTranslation(['recipients']);
  const { watch } = useFormContext<TCreateRecipientFields>();
  const type = watch('recipientType');
  const isCorporate = type === 'corporate';
  return (
    <>
      {isCorporate && (
        <FloatingInputField
          name="legalName"
          label={t('recipients:addModal.legalName')}
        />
      )}
      {!isCorporate && (
        <HStack spacing={2} alignItems="flex-start">
          <FloatingInputField
            name="firstName"
            label={t('recipients:addModal.firstName')}
          />
          <FloatingInputField
            name="lastName"
            label={t('recipients:addModal.lastName')}
          />
        </HStack>
      )}
    </>
  );
};

const ChooseType = () => {
  const { t } = useTranslation(['recipients']);
  const { watch, setValue, unregister } =
    useFormContext<TCreateRecipientFields>();
  const type = watch('recipientType');
  const { senderAccount } = useAddRecipientWizardContext();

  const selection = selectionIndex[type as TRecipientMainType];

  const typeOptions = useMemo(() => {
    return senderAccount
      ? [
          t('recipients:corporate'),
          t('recipients:individual'),
          t('recipients:internal'),
        ]
      : [t('recipients:corporate'), t('recipients:individual')];
  }, [t, senderAccount]);

  const handleChange = useCallback(
    (recipientTypeIndex: number) => {
      switch (recipientTypeIndex) {
        case 0:
          setValue('recipientType', 'corporate');
          unregister('firstName');
          unregister('lastName');
          break;
        case 1:
          setValue('recipientType', 'individual');
          unregister('legalName');
          unregister('firstName');
          unregister('lastName');
          break;
        case 2:
          setValue('recipientType', 'internal');
          unregister('legalName');
          unregister('firstName');
          unregister('lastName');
      }
    },
    [setValue, unregister],
  );

  return (
    <Box mb={3}>
      <SegmentedPicker
        options={typeOptions}
        onSelectionChange={handleChange}
        selection={selection}
        isFullWidth
      />
    </Box>
  );
};
