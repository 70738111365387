import { isArray } from 'lodash';
import { isNilOrEmpty } from './is-nil-or-empty';

export const createFormData = (formData: FormData, obj: any, subKey = '') => {
  for (const key in obj) {
    const value = obj[key];
    if (isNilOrEmpty(value)) {
      continue;
    }
    const subKeyComposed = subKey ? subKey + '.' + key : key;

    if (isArray(value)) {
      value.forEach((element) => {
        formData.append(key, element);
      });
      continue;
    }

    if (typeof value === 'object') {
      createFormData(formData, value, subKeyComposed);
    } else {
      formData.append(subKeyComposed, value);
    }
  }
};
