import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { RecipientInfoForm } from './RecipientInfoForm';
import { RecipientSuccess } from './RecipientSuccess';
import {
  TRecipientDto,
  TCreateRecipientFields,
  TRecipientType,
  TAccountDto,
  TPaymentType,
} from '@payler/api/client-office';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { validateAccountDetails } from 'uk-modulus-check';

const ACCOUNT_IDENTIFIER_MAX_LENGTH = 34;
const BANK_NAME_MAX_LENGTH = 64;

export type TAddRecipientWizardStep = 'recipientInfo' | 'success';

type TAddRecipientWizardContext = {
  onFinishWizard?: VoidFunction;
  onFirstStepBack?: VoidFunction;
  recipient?: TRecipientDto;
  setRecipient?: Dispatch<SetStateAction<TRecipientDto | undefined>>;
  step: TAddRecipientWizardStep;
  setStep: Dispatch<SetStateAction<TAddRecipientWizardStep>>;
  senderAccount?: TAccountDto;
};

const AddRecipientWizardContext = createContext<TAddRecipientWizardContext>(
  {} as TAddRecipientWizardContext,
);

export type TAddRecipientWizardProps = {
  /** Обработчик завершения последнего шага визарда */
  onFinishWizard?: VoidFunction;
  /** Обработчик выхода с первого шага визарда (если не передан, то не будет кнопки "Назад" на первом шаге) */
  onFirstStepBack?: VoidFunction;
  recipient?: TRecipientDto;
  setRecipient?: Dispatch<SetStateAction<TRecipientDto | undefined>>;
  initialStep?: TAddRecipientWizardStep;
  senderAccount?: TAccountDto;
};

const useResolver = () => {
  const { t } = useTranslation();
  return yupResolver(
    yup.object().shape({
      legalName: yup.string().when('recipientType', {
        is: 'corporate',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      firstName: yup.string().when('recipientType', {
        is: (value: TRecipientType) =>
          value === 'internal' || value === 'individual',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      lastName: yup.string().when('recipientType', {
        is: (value: TRecipientType) =>
          value === 'internal' || value === 'individual',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      country: yup.string().when('recipientType', {
        is: (value: TRecipientType) => value !== 'internal',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      postalCode: yup.string().when(['recipientType', 'paymentType'], {
        is: (recipientType: TRecipientType, paymentType: TPaymentType) =>
          recipientType !== 'internal' && paymentType !== 'fps',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      city: yup.string().when(['recipientType', 'paymentType'], {
        is: (recipientType: TRecipientType, paymentType: TPaymentType) =>
          recipientType !== 'internal' && paymentType !== 'fps',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      street: yup.string().when(['recipientType', 'paymentType'], {
        is: (recipientType: TRecipientType, paymentType: TPaymentType) =>
          recipientType !== 'internal' && paymentType !== 'fps',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      accountIdentifier: yup.string().when(['recipientType', 'paymentType'], {
        is: (recipientType: TRecipientType, paymentType: TPaymentType) =>
          recipientType !== 'internal' && paymentType !== 'fps',
        then: (schema) =>
          schema.required(t('validate.required')).max(
            ACCOUNT_IDENTIFIER_MAX_LENGTH,
            t('validate.maxAccountIdentifierLength', {
              maxAccountIdentifierLength: ACCOUNT_IDENTIFIER_MAX_LENGTH,
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      currency: yup.string().when('recipientType', {
        is: (value: TRecipientType) => value !== 'internal',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      bic: yup.string().when(['recipientType', 'paymentType'], {
        is: (recipientType: TRecipientType, paymentType: TPaymentType) =>
          recipientType !== 'internal' && paymentType !== 'fps',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      bankCountry: yup.string().when('recipientType', {
        is: (value: TRecipientType) => value !== 'internal',
        then: (schema) => schema.required(t('validate.required')),
        otherwise: (schema) => schema.nullable(),
      }),
      bankName: yup
        .string()
        .when('recipientType', {
          is: (value: TRecipientType) => value !== 'internal',
          then: (schema) => schema.required(t('validate.required')),
          otherwise: (schema) => schema.nullable(),
        })
        .max(
          BANK_NAME_MAX_LENGTH,
          t('validate.maxBankNameLength', {
            maxBankNameLength: BANK_NAME_MAX_LENGTH,
          }),
        ),
      internalAccountIdentifier: yup.string().when('recipientType', {
        is: 'internal',
        then: (schema) =>
          schema.required(t('validate.required')).max(
            ACCOUNT_IDENTIFIER_MAX_LENGTH,
            t('validate.maxAccountIdentifierLength', {
              maxAccountIdentifierLength: ACCOUNT_IDENTIFIER_MAX_LENGTH,
            }),
          ),
        otherwise: (schema) => schema.nullable(),
      }),
      ukAccountNumber: yup.string().when('paymentType', {
        is: 'fps',
        then: (schema) =>
          schema.required(t('validate.required')).test((value, ctx) => {
            if (!value || !ctx.parent.ukSortCode) return true;
            const isValidCredentials = validateAccountDetails(
              ctx.parent.ukSortCode,
              value,
            );
            if (isValidCredentials) return true;

            return ctx.createError({
              message: t('validate.notValidUKAccountCredentials'),
            });
          }),
        otherwise: (schema) => schema.nullable(),
      }),
      ukSortCode: yup.string().when('paymentType', {
        is: 'fps',
        then: (schema) =>
          schema.required(t('validate.required')).test((value, ctx) => {
            if (!value || !ctx.parent.ukAccountNumber) return true;
            const isValidCredentials = validateAccountDetails(
              value,
              ctx.parent.ukAccountNumber,
            );
            if (isValidCredentials) return true;

            return ctx.createError({
              message: t('validate.notValidUKAccountCredentials'),
            });
          }),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
  );
};

export const AddRecipientWizard: FC<TAddRecipientWizardProps> = ({
  onFinishWizard,
  onFirstStepBack,
  setRecipient,
  initialStep,
  recipient,
  senderAccount,
}) => {
  const [step, setStep] = useState<TAddRecipientWizardStep>(
    initialStep || 'recipientInfo',
  );

  const ctx: TAddRecipientWizardContext = useMemo(() => {
    return {
      onFinishWizard,
      onFirstStepBack,
      recipient,
      setRecipient,
      step,
      setStep,
      senderAccount,
    };
  }, [
    onFinishWizard,
    onFirstStepBack,
    recipient,
    senderAccount,
    setRecipient,
    step,
  ]);

  const methods = useForm<TCreateRecipientFields>({
    resolver: useResolver(),
    defaultValues: recipient
      ? {
          recipientType: recipient.recipientType,
          legalName: recipient.legalName,
          firstName: recipient.firstName,
          lastName: recipient.lastName,
          country: recipient.registrationAddress.country,
          postalCode: recipient.registrationAddress.postalCode,
          city: recipient.registrationAddress.city,
          street: recipient.registrationAddress.street,
          bic: recipient.account.bic,
          currency:
            recipient.recipientType !== 'internal'
              ? recipient.account.currency.toLowerCase()
              : undefined,
          accountIdentifier:
            recipient.recipientType !== 'internal'
              ? recipient.account.iban || recipient.account.accountNumber
              : undefined,
          bankCountry: recipient.account.bankCountry,
          bankName: recipient.account.bankName,
          internalAccountIdentifier:
            recipient.recipientType === 'internal'
              ? recipient.account.accountNumber || recipient.account.iban
              : undefined,
          paymentType: recipient.account.ukAccountNumber ? 'fps' : 'swift',
          ukAccountNumber: recipient.account.ukAccountNumber,
          ukSortCode: recipient.account.ukSortCode,
        }
      : {
          recipientType: 'corporate',
          currency: !!senderAccount ? senderAccount.currency : undefined,
          paymentType: 'swift',
        },
    mode: 'onChange',
  });

  return (
    <AddRecipientWizardContext.Provider value={ctx}>
      <FormProvider {...methods}>
        {step === 'recipientInfo' && <RecipientInfoForm />}
        {step === 'success' && <RecipientSuccess />}
      </FormProvider>
    </AddRecipientWizardContext.Provider>
  );
};

export const useAddRecipientWizardContext = () =>
  useContext(AddRecipientWizardContext);
