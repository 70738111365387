import {
  TAccountDto,
  TRecipientDto,
  TCreateOutgoingTransferDto,
  TTransfersFeeDto,
  TTransferSystemDto,
  TRecipientType,
  EFeeAllocationEnum,
} from '@payler/api/client-office';
import React, { useCallback, useEffect } from 'react';
import {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useContext,
  createContext,
} from 'react';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { TAddRecipientWizardStep } from '../AddRecipientModal/AddRecipientWizard';
import { TransferSelectRecipient } from './TransferSelectRecipient';
import { TransferError } from './TransferError';
import { TransferConfirmation } from './TransferConfirmation';
import { TransferSuccess } from './TransferSuccess';
import { TransferDemo } from './TransferDemo';
import { TransferOtp } from './TransferOtp';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { usePermissions } from '../../hooks/use-permissions';
import { TransferDetailsForm } from './TransferDetails/TransferDetailsForm';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import { SelfTransferWarning } from './SelfTransferWarning';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_FILE_NAME_LENGTH,
  MAX_FILES_QUANTITY,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MIN_DESCRIPTION_LENGTH,
  ONE_MB,
  VALID_DECIMAL_REGEXP,
  VALID_FILE_FORMATS,
  TCurrency,
} from '@payler/bank-utils';
import { useWhiteLabeledComponents } from '../../config/ConfigProvider';

export const AMOUNT_FOR_MANDATORY_DOCUMENT = 100000;
export const AMOUNT_FOR_MANDATORY_DESCRIPTION = 1000;

const useResolver = (
  senderBalance?: number,
  feeAmount?: number,
  isSelfTransfer?: boolean,
  currency?: TCurrency,
  recepientType?: TRecipientType,
) => {
  const { t } = useTranslation();

  return yupResolver(
    yup.object().shape({
      amount: yup
        .string()
        .required(t('validate.required'))
        .typeError(t('validate.required'))
        .matches(VALID_DECIMAL_REGEXP, t('validate.invalidDecimalAmount'))
        .test((value, ctx) => {
          if (value && Number(value) <= 0) {
            return ctx.createError({
              message: t('validate.amountGreaterThanZero'),
            });
          }
          if (
            value &&
            Number(value) + (feeAmount || 0) > (senderBalance || 0)
          ) {
            return ctx.createError({ message: t('validate.notEnoughMoney') });
          }
          return true;
        }),
      description: yup
        .string()
        .max(
          MAX_DESCRIPTION_LENGTH,
          t('validate.maxDescriptionLength', {
            maxDescriptionLength: MAX_DESCRIPTION_LENGTH,
          }),
        )
        .test((value, ctx) => {
          if (
            !!value &&
            value.length > 0 &&
            value.length < MIN_DESCRIPTION_LENGTH
          ) {
            return ctx.createError({
              message: t('validate.minDescriptionLength', {
                minDescriptionLength: MIN_DESCRIPTION_LENGTH,
              }),
            });
          }
          return true;
        })
        .when('amount', {
          is: (value: string) =>
            Number(value) >= AMOUNT_FOR_MANDATORY_DESCRIPTION &&
            !isSelfTransfer,
          then: (schema) => schema.required(t('validate.required')),
        }),
      files: yup.mixed().test((files: File[], ctx) => {
        if (isSelfTransfer) return true;
        if (
          ctx.parent.amount >= AMOUNT_FOR_MANDATORY_DOCUMENT &&
          isEmpty(files)
        ) {
          return ctx.createError({ message: t('validate.required') });
        }
        if (currency === 'cny' && isEmpty(files)) {
          return ctx.createError({ message: t('validate.required') });
        }
        if (isEmpty(files)) {
          return true;
        }
        if (files.length > MAX_FILES_QUANTITY) {
          return ctx.createError({
            message: t('validate.maxFilesQuantity', {
              maxQuantity: MAX_FILES_QUANTITY,
            }),
          });
        }
        for (const file of files) {
          if (file.size > MAX_FILE_SIZE * ONE_MB) {
            return ctx.createError({
              message: t('validate.maxFileSize', {
                maxFileSize: MAX_FILE_SIZE,
              }),
            });
          }
          if (!VALID_FILE_FORMATS.includes(file.type)) {
            return ctx.createError({
              message: t('validate.invalidFileFormat'),
            });
          }
          if (file.name.length > MAX_FILE_NAME_LENGTH) {
            return ctx.createError({
              message: t('validate.maxFileNameLength', {
                maxLength: MAX_FILE_NAME_LENGTH,
              }),
            });
          }
        }
        const totalSize = files.reduce(
          (accumulator, currentFile) => accumulator + currentFile.size,
          0,
        );
        if (totalSize > MAX_TOTAL_FILE_SIZE * ONE_MB) {
          return ctx.createError({
            message: t('validate.totalMaxFileSize', {
              maxTotalFileSize: MAX_TOTAL_FILE_SIZE,
            }),
          });
        }

        return true;
      }),
    }),
  );
};

export type TTransferDetailsForm = {
  amount?: number;
  description?: string;
  files?: File[];
  senderAccountId?: string;
  receiverAccountId?: string;
  feeAllocation?: EFeeAllocationEnum;
};

export type TTransferWizardStep =
  | 'select'
  | 'selfWarning'
  | 'form'
  | 'info'
  | 'otp'
  | 'success'
  | 'demo'
  | 'error';

export type TTransferWizardMode =
  | 'transfer'
  | 'addRecipient'
  | 'recipientSuccess';

type TTransferWizardContext = {
  initialStep: TTransferWizardStep;
  step: TTransferWizardStep;
  setStep: Dispatch<SetStateAction<TTransferWizardStep>>;
  initialSenderAccount?: TAccountDto;
  senderAccount?: TAccountDto;
  setSenderAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  recipient?: TRecipientDto;
  setRecipient: Dispatch<SetStateAction<TRecipientDto | undefined>>;
  transferDetails?: TTransferDetailsForm;
  setTransferDetails: Dispatch<
    SetStateAction<TTransferDetailsForm | undefined>
  >;
  transferId?: string | undefined;
  setTransferId: Dispatch<SetStateAction<string | undefined>>;
  transferSystem: TTransferSystemDto | undefined;
  setTransferSystem: Dispatch<SetStateAction<TTransferSystemDto | undefined>>;
  transfersFee: TTransfersFeeDto | undefined;
  setTransfersFee: Dispatch<SetStateAction<TTransfersFeeDto | undefined>>;
  transfer: TCreateOutgoingTransferDto | undefined;
  setTransfer: Dispatch<SetStateAction<TCreateOutgoingTransferDto | undefined>>;
  isNewRecipientState: boolean;
  setIsNewRecipientState: Dispatch<SetStateAction<boolean>>;
  setMode: Dispatch<SetStateAction<TTransferWizardMode>>;
  setAddRecipientStep: Dispatch<
    SetStateAction<TAddRecipientWizardStep | undefined>
  >;
  receiverAccount: TAccountDto | undefined;
  setReceiverAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  isSelfTransfer: boolean;
  setIsSelfTransfer: Dispatch<SetStateAction<boolean>>;
  isRepeatTransfer: boolean | undefined;
  error: unknown;
  setError: Dispatch<unknown>;
};

const TransferWizardContext = createContext<TTransferWizardContext>(
  {} as TTransferWizardContext,
);

export const useTransferWizardContext = () =>
  useContext<TTransferWizardContext>(TransferWizardContext);

export const TransferWizard = ({
  closeModal,
  initialSenderAccount,
  initialRecipient,
  initialStep = 'select',
  initialFormValues,
  isSelfTransfer: isSelfTransferDefault,
  isRepeatTransfer,
}: {
  closeModal: VoidFunction;
  initialSenderAccount?: TAccountDto;
  initialRecipient?: TRecipientDto;
  initialStep?: TTransferWizardStep;
  initialFormValues?: TTransferDetailsForm;
  isSelfTransfer?: boolean;
  isRepeatTransfer?: boolean;
}) => {
  const [mode, setMode] = useState<TTransferWizardMode>('transfer');
  const [step, setStep] = useState<TTransferWizardStep>(initialStep);
  const [error, setError] = useState<unknown>();
  const [transferDetails, setTransferDetails] = useState<
    TTransferDetailsForm | undefined
  >(initialFormValues);
  const [recipient, setRecipient] = useState<TRecipientDto | undefined>(
    initialRecipient,
  );

  const [senderAccount, setSenderAccount] = useState<TAccountDto | undefined>(
    initialSenderAccount,
  );
  const [receiverAccount, setReceiverAccount] = useState<
    TAccountDto | undefined
  >();

  const [transferId, setTransferId] = useState<string>();
  const [transferSystem, setTransferSystem] = useState<
    TTransferSystemDto | undefined
  >();
  const [transfersFee, setTransfersFee] = useState<
    TTransfersFeeDto | undefined
  >();
  const [transfer, setTransfer] = useState<
    TCreateOutgoingTransferDto | undefined
  >();
  const [isNewRecipientState, setIsNewRecipientState] =
    useState<boolean>(false);
  const [addRecipientStep, setAddRecipientStep] = useState<
    TAddRecipientWizardStep | undefined
  >();
  const [isSelfTransfer, setIsSelfTransfer] = useState<boolean>(
    isSelfTransferDefault || false,
  );
  const { isGlobalAccounts } = usePermissions();

  const methods = useForm<TTransferDetailsForm>({
    resolver: useResolver(
      senderAccount?.amount,
      transfersFee?.feeAmount,
      isSelfTransfer,
      senderAccount?.currency,
      recipient?.recipientType,
    ),
    defaultValues: !isGlobalAccounts
      ? { amount: 5000, description: 'ads' }
      : {
          receiverAccountId: receiverAccount?.id,
          senderAccountId: senderAccount?.id,
          feeAllocation: EFeeAllocationEnum.our,
          ...transferDetails,
        },
    mode: 'onChange',
  });
  const { setValue, trigger } = methods;

  const { data: accounts } = useAccountsQuery();

  const getSelectedReceiverAccount = useCallback(() => {
    if (receiverAccount) {
      return receiverAccount;
    }
    if (transferDetails?.receiverAccountId) {
      return accounts?.find(
        (acc) => acc.id === transferDetails.receiverAccountId,
      );
    }

    return getDefaultReceiverAccount(
      senderAccount?.id,
      senderAccount?.currency,
      accounts,
    );
  }, [
    receiverAccount,
    transferDetails?.receiverAccountId,
    accounts,
    senderAccount?.id,
    senderAccount?.currency,
  ]);

  useEffect(() => {
    const selectedReceiverAccount = getSelectedReceiverAccount();
    setReceiverAccount(selectedReceiverAccount);
    setValue('receiverAccountId', selectedReceiverAccount?.id);
  }, [getSelectedReceiverAccount, setReceiverAccount, setValue]);

  useEffect(() => {
    if (transfersFee?.feeAmount !== undefined) {
      trigger('amount');
    }
  }, [transfersFee?.feeAmount]);

  const ctxValue = useMemo<TTransferWizardContext>(
    () => ({
      initialStep,
      step,
      setStep,
      initialSenderAccount,
      senderAccount,
      setSenderAccount,
      recipient,
      setRecipient,
      transferDetails,
      setTransferDetails,
      transferId,
      setTransferId,
      transferSystem,
      setTransferSystem,
      transfersFee,
      setTransfersFee,
      transfer,
      setTransfer,
      isNewRecipientState,
      setIsNewRecipientState,
      setMode,
      setAddRecipientStep,
      receiverAccount,
      setReceiverAccount,
      isSelfTransfer,
      setIsSelfTransfer,
      isRepeatTransfer,
      error,
      setError,
    }),
    [
      initialStep,
      step,
      initialSenderAccount,
      senderAccount,
      recipient,
      transferDetails,
      transferId,
      transferSystem,
      transfersFee,
      transfer,
      isNewRecipientState,
      receiverAccount,
      isSelfTransfer,
      isRepeatTransfer,
      error,
    ],
  );

  const components = useWhiteLabeledComponents();

  const AddRecipientWizard = components.addRecipientWizard;
  return (
    <>
      {mode === 'transfer' && (
        <TransferWizardContext.Provider value={ctxValue}>
          <FormProvider {...methods}>
            <React.Suspense fallback={<LoaderBox minH="312px" />}>
              {step === 'select' && (
                <TransferSelectRecipient
                  onAddRecipientClick={() => {
                    setRecipient(undefined);
                    setMode('addRecipient');
                    setIsNewRecipientState(true);
                  }}
                />
              )}
              {step === 'form' && <TransferDetailsForm />}
              {step === 'info' && <TransferConfirmation />}
              {step === 'success' && <TransferSuccess close={closeModal} />}
              {step === 'otp' && <TransferOtp close={closeModal} />}
              {step === 'demo' && <TransferDemo close={closeModal} />}
              {step === 'selfWarning' && (
                <SelfTransferWarning close={closeModal} />
              )}
              {step === 'error' && <TransferError close={closeModal} />}
            </React.Suspense>
          </FormProvider>
        </TransferWizardContext.Provider>
      )}
      {mode === 'addRecipient' && (
        <AddRecipientWizard
          onFirstStepBack={() => {
            setMode('transfer');
            setStep('select');
            setAddRecipientStep('recipientInfo');
            setIsNewRecipientState(false);
          }}
          onFinishWizard={() => {
            setMode('transfer');
            setStep('form');
          }}
          setRecipient={setRecipient}
          initialStep={addRecipientStep}
          recipient={recipient}
          senderAccount={senderAccount}
        />
      )}
      {mode === 'recipientSuccess' && (
        <AddRecipientWizard initialStep="success" recipient={recipient} />
      )}
    </>
  );
};

const getDefaultReceiverAccount = (
  senderAccountId: string | undefined,
  senderAccountCurrency: string | undefined,
  accounts: TAccountDto[] | null,
) =>
  accounts?.find(
    (account) =>
      account.id !== senderAccountId &&
      account.currency === senderAccountCurrency,
  );
