import React, { ReactElement, ReactNode } from 'react';
import { Box, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { Mail24Icon, UserIcon } from '@payler/ui-icons';
import { ReactComponent } from '*.svg';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { LoaderBox } from '../LoaderBox/LoaderBox';
import {
  BankIcon,
  CalendarIcon,
  FileTextIcon,
  GlobeIcon,
  LocationIcon,
  LockIcon,
  MailIcon,
  PhoneIcon,
  SettingsIcon,
  WalletIcon,
} from '../../icons';
import { replaceIfNoData } from '../../helpers/replaceIfNoData';
import { isNilOrEmpty } from '@payler/bank-utils';
import { useProfileInfo } from '../../hooks/use-profile-info';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useClientQuery } from '../../hooks/clients/queries';
import { useAuth } from '@payler/auth';
import { isIndividualClient } from '@payler/api/client-office';
import { getClientName } from '../../helpers/clients';

export const ProfileInfo = () => {
  const { t } = useTranslation(['profile', 'common']);
  const { data } = useClientQuery();
  const profileInfo = useProfileInfo();
  const { isAuthLoading, changePassword } = useAuth();

  if (isAuthLoading) return <LoaderBox />;

  const { firstName, address, email, phoneNumber } = isIndividualClient(data)
    ? {
        firstName: getClientName(data),
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
      }
    : {
        firstName: profileInfo.name,
        email: profileInfo.email,
        phoneNumber: data.phoneNumber,
        address: profileInfo.address,
      };

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text textStyle={TextStyles.BodyText16Semibold} color="primary.500">
        {t('profile:personalInfo')}
      </Text>
      <Box bg="secondary.500" borderRadius={1.5} width="100%">
        <InfoItem
          label={t('profile:name')}
          value={replaceIfNoData(firstName)}
          icon={UserIcon}
        />
        <InfoItem
          label={t('profile:email')}
          value={replaceIfNoData(email)}
          icon={Mail24Icon}
        />
        <InfoItem
          label={t('profile:mobilePhone')}
          value={
            !isNilOrEmpty(phoneNumber) ? (
              <NumberFormat
                value={phoneNumber}
                format="+## ### ### ###"
                displayType="text"
              />
            ) : (
              '-'
            )
          }
          icon={PhoneIcon}
        />
        <InfoItem
          label={t('profile:address')}
          value={
            address ? replaceIfNoData(Object.values(address).join(' ')) : '-'
          }
          icon={LocationIcon}
        />
        <InfoItem
          label={t('profile:status')}
          value={replaceIfNoData(data.status)}
          icon={SettingsIcon}
        />
        <InfoItem
          label={t('profile:password')}
          value="••••••••••"
          icon={LockIcon}
          actions={
            <Link
              as="button"
              size="xs"
              onClick={changePassword}
              textStyle={TextStyles.Subtitle14Medium}
              data-testid="button_change-password"
            >
              {t('common:change')}
            </Link>
          }
        />
      </Box>
    </VStack>
  );
};

export const CompanyInfo = () => {
  const { t } = useTranslation(['profile']);
  const { data, isLoading } = useClientQuery();
  const { parseBankDate, formatBankDate, regionNames } = useLanguageFeatures();

  if (isLoading) return <LoaderBox />;
  if (!data || data.type !== 'company') return null;

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text textStyle={TextStyles.BodyText16Semibold} color="primary.500">
        {t('profile:companyInfo')}
      </Text>
      <Box bg="secondary.500" borderRadius={1.5} width="100%">
        <InfoItem
          label={t('profile:companyName')}
          value={replaceIfNoData(data.legalName)}
          icon={WalletIcon}
        />
        <InfoItem
          label={t('profile:registrationDate')}
          value={
            (data.companyRegistrationDate
              ? formatBankDate(
                  parseBankDate(data.companyRegistrationDate, 'server', 'date'),
                  'display',
                  'date',
                )
              : '-') ?? '-'
          }
          icon={CalendarIcon}
        />
        <InfoItem
          label={t('profile:countryOfRegistration')}
          value={
            data.countryOfRegistration
              ? regionNames.of(data.countryOfRegistration)
              : '-'
          }
          icon={GlobeIcon}
        />
        <InfoItem
          label={t('profile:taxResidencyCountry')}
          value={
            data.taxResidencyCountry
              ? regionNames.of(data.taxResidencyCountry)
              : '-'
          }
          icon={FileTextIcon}
        />
        <InfoItem
          label={t('profile:address')}
          value={
            data.address
              ? replaceIfNoData(Object.values(data.address).join(' '))
              : '-'
          }
          icon={LocationIcon}
        />
        <InfoItem
          label={t('profile:registrationNumber')}
          value={replaceIfNoData(data.registrationNumber)}
          icon={BankIcon}
        />
        <InfoItem
          label={t('profile:phoneNumber')}
          value={replaceIfNoData(data.phoneNumber)}
          icon={PhoneIcon}
        />
        <InfoItem
          label={t('profile:email')}
          value={replaceIfNoData(data.email)}
          icon={MailIcon}
        />
      </Box>
    </VStack>
  );
};

const InfoItem: React.FC<{
  label: string;
  value?: string | ReactElement;
  icon: typeof ReactComponent;
  actions?: ReactNode | ReactElement;
}> = ({ label, value, icon, actions }) => {
  if (!value) return null;
  return (
    <Flex alignItems="center" py={2} px={3}>
      <Flex
        justifyContent="center"
        alignItems="center"
        width={5}
        height={5}
        mr={2}
        bg="brands.100"
        borderRadius="50%"
        overflow="hidden"
        flex="0 0 auto"
      >
        <Icon as={icon} color="brands.500" w={2} />
      </Flex>
      <VStack
        as="span"
        spacing="2px"
        alignItems="baseline"
        flexDirection="column"
        w="100%"
      >
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {label}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Medium} color="primary.500">
          {value}
        </Text>
      </VStack>
      {actions && <Box>{actions}</Box>}
    </Flex>
  );
};
