export const TransferStateValues = [
  'completed',
  'processing',
  'failed',
  'technicalProblem',
] as const;

export const TransferSystemValues = [
  'sepa',
  'swift',
  'internal',
  'self',
  'fasterUK',
] as const;

export const TransferTypeValues = ['incoming', 'outgoing', 'fx'] as const;
