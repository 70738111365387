import { IS_IOS_PLATFORM } from '@payler/bank-utils';
import { AxiosInstance } from 'axios';
import { Dictionary } from 'lodash';

export async function prepareBlob(
  axios: AxiosInstance,
  url: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  params?: Dictionary<any>,
) {
  let blob: Blob;
  let fileName = '';
  let mimeType = 'application/octet-stream'; // default MIME type

  if (process.env['STORYBOOK_IN_STORYBOOK'] === 'true') {
    fileName = 'test_name_for_storybook.csv';
    blob = new Blob([`some, storybook, csv`, `\n1,2,3`]);
  } else {
    const resp = await axios.get(url, {
      params,
      responseType: 'arraybuffer',
    });

    fileName =
      resp.headers?.['content-disposition']?.match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
      )?.[1] ?? '';
    mimeType = resp.headers['content-type'] || mimeType;
    blob = new Blob([resp.data], { type: mimeType });
  }
  return { blob, fileName };
}

export function downloadFile(blob: Blob, fileName: string) {
  const blobURL = URL.createObjectURL(blob);

  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;

  if (IS_IOS_PLATFORM) {
    tempLink.href += `?filename=${fileName}`;
  }

  tempLink.setAttribute('download', fileName);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
  }, 100);
}
