export const common = {
  selected: 'Selected',
  search: 'Search',
  apply: 'Apply',
  back: 'Back',
  continue: 'Continue',
  close: 'Close',
  cancel: 'Cancel',
  clickToCopy: 'Copy',
  copyAll: 'Copy all',
  copied: 'Done',
  currencies: 'Currency',
  darkMode: 'Dark theme',
  error: 'Error',
  home: 'Home',
  no: 'No',
  noAccess: 'Not enough rights',
  noData: 'No data',
  noOptions: 'No options',
  notFound: {
    header: 'Page not found',
    description: "Looks like we don't have this page",
    linkBack: 'Return to the Home page →',
  },
  errorPage: {
    header: 'Something went wrong',
    description: '',
    linkBack: 'Reload page →',
  },
  pager: {
    of: 'of',
    rowsPerPage: 'Rows\u00a0per\u00a0page',
    showed: 'Showed',
  },
  range: {
    empty: '',
    from: 'From {{start}}',
    fromTo: 'From {{start}} to {{end}}',
    to: 'To {{end}}',
  },
  save: 'Save',
  selectAll: 'Select All',
  selectedCountOf: 'Selected: {{count}} of {{total}}',
  serverError: 'Server error. {{message}}',
  success: 'Success',
  sumRange: {
    from: 'From {{start, number}}',
    fromTo: 'From {{start, number}} to {{end, number}}',
    to: 'To {{end, number}}',
  },
  unknownError: 'Unknown error {{message}}',
  validate: {
    required: 'Required',
    minPasswordLength: 'Password length not less than 8 characters',
    notMatchPasswordPattern:
      'Password must contain at least one uppercase letter, one lowercase letter, number and special character (. -?! @ & +)',
    doNotMatchPassword: 'The passwords entered do not match',
    url: 'Specify the return url correctly',
    ips: 'Specify ip-address correctly',
    incorrectValue: 'Incorrect value',
    invalidFileFormat:
      'Invalid file format. Supported formats are pdf, jpg and png.',
    maxFileSize: 'File size exceeds the maximum limit of {{maxFileSize}} MB.',
    totalMaxFileSize:
      'Total file size exceeds the maximum limit of {{maxTotalFileSize}} MB.',
    maxAccountIdentifierLength:
      'IBAN or Account Number must be less than {{maxAccountIdentifierLength}} characters',
    maxBankNameLength:
      'Bank name must be less than {{maxBankNameLength}} characters',
    notEnoughMoney: 'Not enough money',
    minDescriptionLength:
      'Description length must not be less than {{minDescriptionLength}} characters',
    maxDescriptionLength:
      'Description length must not be greater than {{maxDescriptionLength}} characters',
    invalidDecimalAmount: 'Only two digits are allowed after the decimal point',
    maxMustBeGreaterMin: 'Amount from cannot be greater than to',
    maxAccountNameLength: 'Account name must not be greater than 20 characters',
    maxFileNameLength: 'File name should not exceed {{maxLength}} characters',
    maxFilesQuantity: 'Only {{maxQuantity}} files are allowed at a time',
    amountGreaterThanZero: 'Amount should be greater than 0',
    notValidUKAccountCredentials:
      'Ensure the UK account number and sort code are entered correctly.',
  },
  yes: 'Yes',
  backLink: '← Back',
  apps2FA: 'Free applications for 2FA security',
  change: 'Change',
  confirm: 'Confirm',
  create: 'Create',
  delete: 'Delete',
  filters: 'Filters',
  export: 'Export',
  loading: 'Loading...',
  details: 'Details',
  dates: {
    today: 'today',
    yesterday: 'yesterday',
  },
  clearFilters: 'Clear filters',
  datePicker: {
    maxError: 'Date must be before or equal {{maxDate}}',
    minError: 'Date must be after or equal {{minDate}}',
  },
} as const;
