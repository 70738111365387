import {
  TCreateRecipientCommand,
  TCreateRecipientFields,
} from '@payler/api/client-office';

export const getStructuredRecipientParam = ({
  recipientFieldValues,
  isVisible,
  internalAccountCurrency,
}: {
  recipientFieldValues: TCreateRecipientFields;
  isVisible: boolean;
  internalAccountCurrency?: string;
}) => {
  const {
    recipientType,
    legalName,
    firstName,
    lastName,
    country,
    postalCode,
    city,
    street,
    accountIdentifier,
    currency,
    bic,
    bankCountry,
    bankName,
    internalAccountIdentifier,
    paymentType,
    ukAccountNumber,
    ukSortCode,
  } = recipientFieldValues;

  const structuredRecipient = {
    recipient: {
      recipientType,
      ...(recipientType === 'corporate' && {
        legalName,
      }),
      ...((recipientType === 'individual' || recipientType === 'internal') && {
        firstName,
        lastName,
      }),
      ...(recipientType !== 'internal' &&
        paymentType !== 'fps' && {
          registrationAddress: {
            country,
            postalCode,
            city,
            street: street,
          },
        }),
      account: {
        ...(paymentType === 'fps'
          ? {
              ukAccountNumber,
              ukSortCode,
            }
          : {
              accountIdentifier:
                recipientType === 'internal'
                  ? internalAccountIdentifier
                  : accountIdentifier,
              bic,
            }),
        currency:
          recipientType === 'internal' ? internalAccountCurrency : currency,
        bankCountry,
        bankName,
      },
      isVisible,
    },
  } as TCreateRecipientCommand;
  return structuredRecipient;
};

export const fieldsMap: Record<string, keyof TCreateRecipientFields> = {
  'Recipient.LegalName': 'legalName',
  'Recipient.FirstName': 'firstName',
  'Recipient.LastName': 'lastName',
  'Recipient.RegistrationAddress.Country': 'country',
  'Recipient.RegistrationAddress.PostalCode': 'postalCode',
  'Recipient.RegistrationAddress.City': 'city',
  'Recipient.RegistrationAddress.Street': 'street',
  AccountIdentifier: 'accountIdentifier',
  'Recipient.Account.AccountIdentifier': 'accountIdentifier',
  InternalAccountIdentifier: 'internalAccountIdentifier',
  Currency: 'currency',
  Bic: 'bic',
  'Recipient.Account.BankName': 'bankName',
  'Recipient.Account.BankCountry': 'bankCountry',
  'Recipient.Internal.FirstName': 'firstName',
  'Recipient.Internal.LastName': 'lastName',
  'Recipient.Account.UKAccountNumber': 'ukAccountNumber',
  'Recipient.Account.UKSortCode': 'ukSortCode',
};
