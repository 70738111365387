import { TCreateRecipientCommand } from '@payler/api/client-office';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSpushApi } from '../useSpushApi';

/**
 * добавить нового реципиента
 */
export const useAddRecipientMutation = () => {
  const api = useSpushApi();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['addRecipient'],
    mutationFn: (data: TCreateRecipientCommand) => api.addRecipient(data),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['recipients', 'list'] });
      client.invalidateQueries({
        queryKey: ['recipients', 'transfer', 'list'],
      });
    },
  });
};
