const ANDROID_RULES = [
  'WebView', // If the user agent contains 'WebView', it's likely a WebView.
  'Android.*Version/[0-9]+\\.[0-9]+', // Match Android version format.
  'Android.*wv', // Lollipop and above: WebView will include 'wv'.
  'Linux; U; Android', // Older Chrome Android WebView user agent.
];

const IOS_RULES = [
  '(iPhone|iPod|iPad)(?!.*Safari)', // iOS WebView lacks "Safari" in the User-Agent.
];

const IS_WEB_CLIENT = typeof window !== 'undefined';

const getUserAgent = (): string | undefined =>
  navigator.userAgent || navigator.vendor;

const checkUserAgentMatchRules = (
  userAgent: string | undefined,
  rules: string[],
): boolean => {
  if (userAgent) {
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return regex.test(userAgent);
  }
  return false;
};

export const checkPlatformIsAndroid = (): boolean => {
  const userAgent = getUserAgent();
  return IS_WEB_CLIENT && checkUserAgentMatchRules(userAgent, ANDROID_RULES);
};

export const checkPlatformIsIos = (): boolean => {
  const userAgent = getUserAgent();
  return IS_WEB_CLIENT && checkUserAgentMatchRules(userAgent, IOS_RULES);
};

// Detect the platform
export const PLATFORM: 'IOS' | 'ANDROID' | 'WEB' = checkPlatformIsAndroid()
  ? 'ANDROID'
  : checkPlatformIsIos()
    ? 'IOS'
    : 'WEB';

export const IS_MOBILE_NATIVE_PLATFORM = PLATFORM !== 'WEB';
export const IS_IOS_PLATFORM = PLATFORM === 'IOS';
export const IS_ANDROID_PLATFORM = PLATFORM === 'ANDROID';
